<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> </template>
      <template #content>
        <div class="p-grid">
          <div class="p-col-2">
            <DoughnutChart v-if="Show_Chart" :ChartData="ChartTalepTurData" />
          </div>
        </div>
      </template>
    </Card>
    <Card class="p-m-2">
      <template #title> Bugün Gelen Talepler </template>
      <template #content>
        <DataTable :value="BekleyenTalepListesi" responsiveLayout="scroll">
          <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import DoughnutChart from "../AdminSayfalariComponents/DoughnutChart.vue";
import GlobalServis from "../../../services/GlobalServis";
export default {
  data() {
    return {
      Show_Chart: false,
      columns: [
        { field: "Barkod", header: "Barkod" },
        { field: "MusteriAd", header: "Müşteri Adı" },
        { field: "Ariza", header: "Ariza" },
        { field: "Tarih", header: "Tarih" },
        { field: "UrunTur", header: "Tür" },
        { field: "Marka", header: "Marka" },
        { field: "Model", header: "Model" },
        { field: "TeknikServisAdi", header: "Servis Adı" },
      ],
      BekleyenTalepListesi: [],
      ChartTalepTurData: {},
    };
  },
  methods: {
    initService() {
      GlobalServis.GlobalServis("GET", "GetBekleyenTalep", "").then((res) => {
        if (res.status == 200) {
          this.BekleyenTalepListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      }).then(() =>{ } )
    },
    Chart_TalepTur() {
      GlobalServis.GlobalServis("GET", "GetChartTalepTur", "").then((res) => {
        if (res.status == 200) {
          var tempData = {}, labels = [], datasets = {}, data = [], backgroundColor = [], hoverBackgroundColor = [];
          res.data.forEach(element => {
            labels.push(element.Durum);
            data.push(element.Toplam);
          });
          backgroundColor = ["#42A5F5", "#66BB6A", "#FFA726","#FF5454","#6952C7" ];
          hoverBackgroundColor = ["#64B5F6", "#81C784", "#FFB74D","#FF9D9D", "#AB9CEC"];
          datasets =  [ {
            data: data,
            backgroundColor: backgroundColor,
            hoverBackgroundColor: hoverBackgroundColor
          }];
          tempData = { labels: labels, datasets: datasets };
          this.ChartTalepTurData = tempData
          this.Show_Chart = true;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
  },
  mounted() {
    this.initService();
    this.Chart_TalepTur();
  },
  components: {
    DoughnutChart,
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <Chart type="doughnut" :data="ChartData" :options="chartOptions" class="w-full md:w-15rem" />
  </div>
</template>

<script>
export default {
  props: {
    ChartData: Object
  },
  data() {
    return {
      tempData: {},
      // chartData2: {
      //   labels: ["A", "B", "C"],
      //   datasets: [
      //     {
      //       data: [300, 50, 100],
      //       backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
      //       hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
      //     },
      //   ],
      // },
      // lightOptions: {
      //   plugins: {
      //     legend: {
      //       labels: {
      //         color: "#495057",
      //       },
      //     },
      //   },
      // },
    };
  },
  methods: {
    
  },
  mounted() {

  }
};
</script>

<style></style>